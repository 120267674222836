import * as React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo/js/seo';
import Layout from '../components/layout/js/layout';
import Headline from '../components/headline/js/headline';
import Content from '../components/content/js/content';
import Logo from '../components/logo/js/logo';
import Navigation from '../components/navigation/js/navigation';

interface ContentTemplateProps {
	data: {
		site: {
			siteMetadata: {
				title: string;
				description: string;
				author: {
					name: string;
					url: string;
				};
			};
		};
		markdownRemark: {
			html: string;
			frontmatter: {
				title: string;
				layout: string;
			};
		};
	};
}

const ContentTemplate = ({ data, location }) => {
	return (
		<Layout location={location}>
			<SEO title={data.markdownRemark.frontmatter.title} />
			<div className="g-container g-container--fullwidth g-container--overflow-hidden">
				<div className="g-row g-no-gutters">
					<div className="g-col-12 g-col-lg-6">
						<Logo />
					</div>
					{
						// Set correct modifier class depending on url
						location.pathname === '/' ? (
							<div className="g-col-12 g-col-lg-6">
								<Navigation />
							</div>
						) : null
					}
				</div>
			</div>
			<div className="g-container g-container--overflow-hidden">
				<div className="g-row">
					<div className="g-col-12 g-col-lg-7">
						<Content>
							<Headline size={1}>{data.markdownRemark.frontmatter.title}</Headline>
							<div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
						</Content>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ContentTemplate;

export const query = graphql`
	query ContentTemplateQuery($slug: String!) {
		site {
			siteMetadata {
				title
				description
				author {
					name
					url
				}
			}
		}
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			excerpt
			frontmatter {
				title
			}
		}
	}
`;
