import React from 'react';
import classNames from 'classnames/bind';

const styles = require('../css/content.scss');
const cx = classNames.bind(styles);

const Content = ({ children }) => (
	<article className={cx('m-content')}>
		<div className={cx('m-content__inner')}>{children}</div>
	</article>
);

export default Content;
